.App {
  width: 100vw;
  max-width: 980px;
  margin: 0 auto;
}

.App-header, h1 {
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
}

h1 {
  margin: 0;
}


