.Player-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 35px;
}

.Player-container .current-track {
    font-size: 24px;
}

.Player-container .controls-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.Player-container .controls-container button {
    background-color: var(--translucent-white);
    border: 1px solid var(--white);
    color: var(--white)
}

.Player-container .controls-container button:first-child {
    margin-right: 5px;
}