.steps-navigation-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.steps-navigation-container .previous-step-button {
    margin: 0 auto 0 0;
}

.steps-navigation-container .next-step-button {
    margin: 0 0 0 auto;
}

.steps-navigation-container button {
    background-color: var(--sunglow);
    border: 1px solid var(--sunglow);
    display: flex;
    align-items: center;
}